const account_el = $('#account_id');
const approve_el = $('input[type="submit"][name="approve"]')
const reject_el = $('input[type="submit"][name="reject"]')
const agent_id = $('#agent_id')
function validate_account() {
  return !!account_el.val();
}

// approve_el.toggle(validate_account());
// reject_el.toggle(validate_account());

function fetchAccounts(agent_id) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: '/agent_funds/fetch_accounts',
      dataType: "json",
      data: { agent_id: agent_id },
      error: (xhr, status, error) => {
        const e = "AJAX Error: " + status + error;
        console.error(e);
        reject(e);
      },
      success: (response) => {
        resolve(response);
      }
    });
  });
}

function populateSelect(accounts) {
  account_el.html("<option value=''>Select</option>");
  let selected = '';
  if (accounts.length === 1) selected = 'selected'
  for (let i = 0; i < accounts.length; i++) {
    const ac = accounts[i];
    const optionText = ac.select_name;
    const optionValue = ac.id;
    account_el.append(`<option value="${optionValue}" ${selected} > ${optionText} </option>`);
  }
  if (selected === 'selected') account_el.trigger('change');
}

$(() => {
  agent_id.on('change', function () {
    fetchAccounts($(this).val())
      .then((resp) => {
        return populateSelect(resp.accounts);
      });
  });

  account_el.on('change', function () {
    approve_el.toggle(validate_account());
    reject_el.toggle(validate_account());
  });

  $('#rate').on('change', function () {
    updateUSDAmount();
  });
});

$(document).on('change', '#agent_fund_ids_', function () {
  toggleButtons();
  updateAmounts($(this).val(), $(this).is(':checked'));
});

function toggleButtons() {
  const anyChecked = $('#agent_fund_ids_:checked').length > 0;
  $('.approve-btn').prop('disabled', !anyChecked);
  $('.reject-btn').prop('disabled', !anyChecked);
}

function updateAmounts(rowId, checked) {
  const amount = parseFloat($('#funding_row_' + rowId + ' .funding-amount').text() || '0');
  const amount_el = $('#amount');

  let el_amount = parseFloat(amount_el.val() || '0');
  if (checked) el_amount += amount;
  else el_amount -= amount;
  amount_el.val(el_amount);

  updateUSDAmount(el_amount);
}

function updateUSDAmount(amount = null) {
  if (!amount) {
    const amount_el = $('#amount');
    amount = parseFloat(amount_el.val() || '0');
  }

  const usd_amount_el = $('#usd_amount');
  if (amount == 0) {
    usd_amount_el.val('');
    return;
  }

  const rate_el = $('#rate');
  if (!rate_el.val() || parseFloat(rate_el.val()) == NaN) usd_amount_el.val('');
  else {
    const rate = parseFloat(rate_el.val());
    usd_amount_el.val(amount * rate);
  }
}
function toggleValidClass() {
  const currencyEq = $("#q_currency_id_eq")
  if (currencyEq.val()) {
    currencyEq.removeClass('is-invalid')
  } else {
    currencyEq.addClass('is-invalid')
  }
}
toggleButtons();
toggleValidClass();
